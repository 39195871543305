// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-activity-mgr-user-page-js": () => import("./../../../src/Templates/ActivityMgr/UserPage.js" /* webpackChunkName: "component---src-templates-activity-mgr-user-page-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-landing-public-page-js": () => import("./../../../src/Templates/Landing/PublicPage.js" /* webpackChunkName: "component---src-templates-landing-public-page-js" */),
  "component---src-templates-login-user-page-js": () => import("./../../../src/Templates/Login/UserPage.js" /* webpackChunkName: "component---src-templates-login-user-page-js" */),
  "component---src-templates-member-mgr-user-page-js": () => import("./../../../src/Templates/MemberMgr/UserPage.js" /* webpackChunkName: "component---src-templates-member-mgr-user-page-js" */),
  "component---src-templates-settings-mgr-user-page-js": () => import("./../../../src/Templates/SettingsMgr/UserPage.js" /* webpackChunkName: "component---src-templates-settings-mgr-user-page-js" */),
  "component---src-templates-signup-mgr-event-page-js": () => import("./../../../src/Templates/SignupMgr/EventPage.js" /* webpackChunkName: "component---src-templates-signup-mgr-event-page-js" */),
  "component---src-templates-signup-mgr-user-page-js": () => import("./../../../src/Templates/SignupMgr/UserPage.js" /* webpackChunkName: "component---src-templates-signup-mgr-user-page-js" */),
  "component---src-templates-test-page-js": () => import("./../../../src/Templates/TestPage.js" /* webpackChunkName: "component---src-templates-test-page-js" */)
}

